<template>
  <el-dialog
      :title="!dataForm.id ? '新增' : '修改'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
             label-width="80px">
      <el-form-item label="上级机构" prop="pid">
        <el-popover
            ref="menuListPopover"
            placement="top-start"
            trigger="click">
          <el-tree
              class="flow-tree"
              :data="menuList"
              :props="menuListTreeProps"
              node-key="id"
              ref="menuListTree"
              @current-change="menuListTreeCurrentChangeHandle"
              :default-expand-all="true"
              :highlight-current="true"
              :expand-on-click-node="false">
          </el-tree>
        </el-popover>
        <el-input v-model="dataForm.pname" v-popover:menuListPopover :readonly="true" placeholder="点击选择上级机构"
                  class="menu-list__input"></el-input>
        <!--      <el-input v-model="dataForm.pid" placeholder="父id"></el-input>-->
      </el-form-item>
      <el-form-item label="机构名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="机构名称"></el-input>
      </el-form-item>
      <el-form-item label="机构代码" prop="code">
        <el-input v-model="dataForm.code" placeholder="机构代码"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="updateTime" v-if="dataForm.updateTime">
        <el-input v-model="dataForm.updateTime" disabled placeholder="更新时间"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelSelect">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {treeDataTranslate} from "@/utils";
import AddOrUpdateMixin from "@/mixins/AddOrUpdate";

export default {
  mixins: [AddOrUpdateMixin],
  data() {
    return {
      dataRule: {
        code: [
          {required: true, message: '父id不能为空', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '机构名称不能为空', trigger: 'blur'}
        ],
      },
      menuList: [],
      menuListTreeProps: {
        label: 'name',
        children: 'children'
      }
    }
  },
  methods: {
    init(id) {
      const that = this;
      this.dataForm.id = id || 0
      that.$http({
        url: that.$http.adornUrl('/wechat/orgs/list'),
        method: 'get',
        params: that.$http.adornParams()
      }).then(({data}) => {
        that.menuList = treeDataTranslate(data.data, 'id', 'pid')
      }).then(() => {
        that.visible = true
        that.$nextTick(() => {
          that.$refs['dataForm'].resetFields()
          const dataForm = {
            id:  id || 0,
            pid: '',
            name: '',
            pname: '',
            code: '',
            updateTime: ''
          }
          that.$set(that, 'dataForm', dataForm)
        })
      }).then(() => {
        if (that.dataForm.id) {
          that.$http({
            url: that.$http.adornUrl(`/wechat/orgs/info/${this.dataForm.id}`),
            method: 'get',
            params: that.$http.adornParams()
          }).then(({data}) => {
            if (data && data.code === 0) {
              data = data['orgs'];
              data.updateTime = this.$utils.dateFormat('yyyy-MM-dd hh:mm', data.updateTime)
              that.$set(that, 'dataForm', data)
              that.menuListTreeSetCurrentNode()
            }
          })
        } else {
          // 新增
          this.menuListTreeSetCurrentNode()
        }
      })
    },
    cancelSelect() {
      this.visible = false
      // this.$refs.menuListTree.setCurrentNode({});
    },
    // 菜单树选中
    menuListTreeCurrentChangeHandle(data /*, node*/) {
      this.dataForm.pid = data.id
      this.dataForm.pname = data.name
    },
    // 菜单树设置当前选中节点
    menuListTreeSetCurrentNode() {
      if (this.dataForm.pid !== -1) {
        this.$refs.menuListTree.setCurrentKey(this.dataForm.pid)
        this.dataForm.pname = (this.$refs.menuListTree.getCurrentNode() || {})['name']
      } else {
        this.dataForm.pname = ''
      }

    },
    // 表单提交
    dataFormSubmit() {
      this.doSubmit(`/wechat/orgs/${!this.dataForm.id ? 'save' : 'update'}`, {
        'id': this.dataForm.id || undefined,
        'pid': this.dataForm.pid,
        'code': this.dataForm.code,
        'name': this.dataForm.name,
      })
    }
  }
}
</script>
<style scoped lang="scss">
.tree-scroll {
  width: 200px;
  border: 1px solid #E7E7E7;
  height: 100%;
}

.flow-tree {
  overflow: auto;
  height: 400px;
  margin: 10px;

  > > > .el-tree-node {
    > .el-tree-node__children {
      overflow: visible !important;
    }
  }

}
</style>
